import React, { ReactNode, useMemo } from "react";
import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";
import CustomParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import { useStableMemo } from "@autocorp/react-core/hooks/useStableMemo";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { Placeholder } from "~/components/Placeholder";

import { useAuth } from "@api/auth";
import { navigate } from "@utils/navigate";
import { MOUSEFLOW_READY, useMouseflow } from "@utils/mouseflow";
import { MIXPANEL_READY, useMixpanel } from "@utils/mixpanel";
import { INTERCOM_READY, useIntercom } from "@utils/intercom";
import { useHotjar } from "@utils/hotjar";

import { ToastProvider, useViewContext, wrapWithProviders } from "~/state";
import { ViewModelElement, PageProps, isViewComponent } from "~/model/view";

import type { GatsbyBrowser } from "gatsby";

export {
	useMouseflow,
	useMixpanel,
	useIntercom,
	useHotjar,
	MOUSEFLOW_READY,
	MIXPANEL_READY,
	INTERCOM_READY,
};

dayjs.extend(CustomParseFormat);
dayjs.extend(utc);
dayjs.extend(relativeTime);

const useStyles = makeStyles(() => ({
	rootContainer: {
		maxHeight: "100vh",
	},
}));

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
	element,
}) => {
	return wrapWithProviders(element);
};

interface IWrapPageElementArgs {
	element: ViewModelElement;
	props: PageProps;
}

interface IWrapPageElement {
	(args: IWrapPageElementArgs): ReactNode;
}

const WrapPageComponent: React.FC<IWrapPageElementArgs> = ({
	element,
	props,
}) => {
	const { location } = props;
	const { user, loading, firstAuth } = useAuth();
	const styles = useStyles();

	const {
		getRedirect,
		getTitle,
		internalScroll,
		internalPad,
		viewWrapper,
		layoutProps,
		hasToast = true,
	} = (isViewComponent(element) && element.type) || {};

	const redirect = useStableMemo(
		() =>
			(getRedirect &&
				getRedirect({
					user,
					loading,
					firstAuth,
					location,
				})) ||
			null,
		[getRedirect, user, loading, firstAuth, location],
	);

	const { state } = useViewContext();
	const title = useMemo(
		() => (getTitle && getTitle(location)) || state.title,
		[getTitle, location, state.title],
	);

	const pageProps = useStableMemo(
		() => ({
			...props,
			/*
			View state can be passed into the page
			props if necessary, for now it is omitted.
		*/
			// viewState: state,
			navigate,
		}),
		[props],
	);

	const Provider = useMemo(
		() => (hasToast ? ToastProvider : Placeholder),
		[hasToast],
	);

	if (!isViewComponent(element)) {
		return element;
	}

	const { layout: Layout, showBack, backTo } = element.type;

	return (
		<Provider>
			{redirect || (
				<div className={styles.rootContainer}>
					<Layout.Render
						title={title}
						location={location}
						pageProps={pageProps}
						showBack={showBack}
						backTo={backTo}
						internalScroll={internalScroll}
						internalPad={internalPad}
						viewWrapper={viewWrapper}
						layoutProps={layoutProps}
					>
						{element}
					</Layout.Render>
				</div>
			)}
		</Provider>
	);
};

export const wrapPageElement: IWrapPageElement = (props) => (
	<React.Fragment>
		<CssBaseline />
		<WrapPageComponent {...props} />
	</React.Fragment>
);
