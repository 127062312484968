import yn from "yn";
import { ReadyPromise } from "@autocorp/web-core-utils/lib/ReadyPromise";

export const INTERCOM_READY = "__intercomReady";
export const WITH_INTERCOM = yn(process.env.GATSBY_WITH_INTERCOM, { default: false });
export const mpReadyPromise = new ReadyPromise();
export const useIntercom = WITH_INTERCOM;

// if (!useIntercom) mpReadyPromise.cancel();

interface IntercomProps {
	api_base: string;
	app_id: string;
	user_id: string;
	name: string;
	email: string;
	created_at?: string;
	first_seen?: string;
	owner?: string;
	owner_name?: string;
	companies?: {
		company_id?: number;
		created_at?: string;
		name?: string;
		monthly_spend?: number;
		size?: number;
		plan?: string;
		website?: string;
		industry?: string;
	}[];
	// TODO: add account details
}

interface Intercom {
	boot: (props: IntercomProps) => void;
	trackEvent: (eventName: string, metadata: any) => void;
	startChecklist: (id: string | number) => void;
	showArticle: (id: string | number) => void;
}

declare global {
	interface Global {
		Intercom?: Intercom;
	}
	interface Window {
		Intercom?: Intercom;
		[INTERCOM_READY]?: VoidFunction | true;
	}
}

export type IntercomEventOptions = any;
type DealerPortalAppContext = {
	dealerId?: number;
	userEmail: string;
};

const getIntercomInstance = () => {
	const trigger = (funcName: string, ...args: any[]) => {
		console.log(funcName, args, window.Intercom)
		// @ts-ignore
		if (!window.Intercom) {
			return;
		}
		// @ts-ignore
		window.Intercom(funcName, ...args);
	}

	return {
		boot: (props: IntercomProps) => trigger("boot", props),
		trackEvent: (eventName: string, metadata: any) => trigger("trackEvent", eventName, metadata),
		startChecklist: (id: string | number) => trigger("startChecklist", id),
		showArticle: (id: string | number) => trigger("showArticle", id),
	}
};

export interface AnalyticsProps {
	visitor: {
		id: string;
		email: string;
		full_name: string;
		role?: string;
	};

	account: {
		id?: number;
		name: string;
		is_paying?: boolean;
		monthly_value?: string;
		planLevel?: string;
		planPrice?: string;
		creationDate?: string;
		website?: string;
		industry?: string;
	};
}

/**
 * Registers/attaches a set of super properties with every event sent to Intercom
 * @param properties An associative array of properties to store with each fired event
 */
const register = (props: AnalyticsProps) => {
	const intercomInst = getIntercomInstance();

	if (!useIntercom) {
		return;
	}

	console.log("Register intercom:", props);
	intercomInst.boot({
		api_base: "https://api-iam.intercom.io",
		app_id: "hzpos9st",
		user_id: props.visitor.id,
		name: props.visitor.full_name, // Full name
		email: props.visitor.email, // the email for your user
		created_at: props.account.creationDate, // Signup date as a Unix timestamp
		first_seen: props.account.creationDate, // Signup date as a Unix timestamp

		companies: [{
			company_id: props.account.id,
			created_at: props.account.creationDate,
			name: props.account.name,
			plan: props.account.planLevel,
			// monthly_spend: 49,
			// size: 85,
			// website: props.account.website,
			// industry: props.account.industry,
		}],
	});
};

// /**
//  * Fires an event to be tracked in Intercom
//  * @param eventOptions Options that include the name of the event itself and any context variables that may be required
//  * @param appContext Dealer Portal app context which consists of `dealerId` and `userEmail`
//  */
// const fireEvent = (
//     eventOptions: IntercomEventOptions,
//     appContext?: DealerPortalAppContext,
// ) => {
//     const intercomInst = getIntercomInstance();
//     mpReadyPromise.then(() => {
//         intercomInst.track(eventOptions.event, (eventOptions as unknown as Record<"context", any>).context);
//         if (appContext) intercomInst.register(appContext);
//     });
// };

export const intercom = { register };
